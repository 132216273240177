import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const TAXI_PASS_URL = `${process.env.REACT_APP_BASE_URL}/taxi-pass/web/requests`;
const TAXI_PASS_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/taxi-pass/by-id`;
const EXPORT_TAXI_REPORT_API = `${process.env.REACT_APP_BASE_URL}/report/get-taxipass-report`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface taxiPassListProps {
  data: any[];
  taxiPassData: any[];
  taxiReportData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: taxiPassListProps = {
  data: [],
  taxiPassData: [],
  taxiReportData: [],
  status: null,
  message: null,
};

export const TaxiPassSlice = createSlice({
  name: 'TaxiPassSlice',
  initialState,
  reducers: {
    // get user list
    taxiPassListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    taxiPassListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    taxiPassDetailsSuccess: (
      state,
      action: PayloadAction<{ taxiPassData: any[]; status: boolean; message: string }>,
    ) => {
      state.taxiPassData = action.payload.taxiPassData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    taxiPassDetailsFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    taxiExportSuccessSuccess: (
      state,
      action: PayloadAction<{ taxiReportData: any[]; status: boolean; message: string }>,
    ) => {
      state.taxiReportData = action.payload.taxiReportData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    taxiExportSuccessFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  taxiPassListSuccess,
  taxiPassListFailure,
  taxiPassDetailsSuccess,
  taxiPassDetailsFailure,
  taxiExportSuccessSuccess,
  taxiExportSuccessFailure,
} = TaxiPassSlice.actions;

export const fetchTaxiPassList =
  (page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${TAXI_PASS_URL}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        taxiPassListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        taxiPassListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getTaxiPassDetailById = (id: number) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(`${TAXI_PASS_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      taxiPassDetailsSuccess({
        status: response.data.status,
        message: response.data.message,
        taxiPassData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      taxiPassDetailsFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const exportTaxiReport =
  (employee_id: any, reporting_manager: any, start_date: any, end_date: any, status: any) =>
  async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }

    // Build the base URL for the API
    let api_url = `${EXPORT_TAXI_REPORT_API}`;

    // Create an array to hold the query parameters
    const params: string[] = [];

    // Add parameters if they are provided
    if (employee_id) params.push(`employee_id=${employee_id}`);
    if (reporting_manager) params.push(`reporting_manager=${reporting_manager}`);
    if (start_date) params.push(`start_date=${start_date}`);
    if (end_date) params.push(`end_date=${end_date}`);
    if (status) params.push(`status=${status}`);

    // If there are parameters, append them to the API URL
    if (params.length > 0) {
      api_url += `?${params.join('&')}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        taxiExportSuccessSuccess({
          status: response.data.status,
          message: response.data.message,
          taxiReportData: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        taxiExportSuccessFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export default TaxiPassSlice.reducer;
