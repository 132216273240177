import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const DESIGNATION_LIST = `${process.env.REACT_APP_BASE_URL}/designation/list`;
const DESIGNATION_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/designation/by-id`;
const DESIGNATION_ADD = `${process.env.REACT_APP_BASE_URL}/designation/add`;
const DESIGNATION_UPDATE = `${process.env.REACT_APP_BASE_URL}/designation/update`;
const DESIGNATION_DELETE = `${process.env.REACT_APP_BASE_URL}/designation/delete`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface DesignationListProps {
  data: any[];
  designationData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: DesignationListProps = {
  data: [],
  designationData: [],
  status: null,
  message: null,
};

export const DesignationSlice = createSlice({
  name: 'DesignationSlice',
  initialState,
  reducers: {
    // get user list
    designationListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    designationListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    designationDetailsSuccess: (
      state,
      action: PayloadAction<{ designationData: any[]; status: boolean; message: string }>,
    ) => {
      state.designationData = action.payload.designationData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    designationDetailsFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user add
    designationAddSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    designationAddFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user update
    designationUpdateSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    designationUpdateFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user delete
    designationDeleteSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    designationDeleteFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  designationListSuccess,
  designationListFailure,
  designationDetailsSuccess,
  designationDetailsFailure,
  designationAddSuccess,
  designationAddFailure,
  designationUpdateSuccess,
  designationUpdateFailure,
  designationDeleteSuccess,
  designationDeleteFailure,
} = DesignationSlice.actions;

export const fetchDesignationList =
  (page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${DESIGNATION_LIST}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        designationListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        designationListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getDesignationDetailById = (id: number) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(`${DESIGNATION_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      designationDetailsSuccess({
        status: response.data.status,
        message: response.data.message,
        designationData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      designationDetailsFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const addDesignation = (body: any, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.post(DESIGNATION_ADD, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    dispatch(
      designationAddSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchDesignationList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      designationAddFailure({
        status: false,
        message: error.message,
      }),
    );
    console.error(error);
  }
};

export const updateDesignation =
  (id: number, body: any, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }
    try {
      const response = await axios.put(`${DESIGNATION_UPDATE}/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(
        designationUpdateSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchDesignationList(1, 10, search));

      return response;
    } catch (error: any) {
      dispatch(
        designationUpdateFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const deleteDesignation =
  (id: any, page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }
    try {
      const response = await axios.delete(`${DESIGNATION_DELETE}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        designationDeleteSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchDesignationList(page, rowsPerPage, search));

      return response;
    } catch (error: any) {
      dispatch(
        designationDeleteFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export default DesignationSlice.reducer;
