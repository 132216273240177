import axios from '../../../utils/axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../../Store';

const LOGIN_API_URL = `${process.env.REACT_APP_BASE_URL}/auth/signin`;

const TOKEN_STORAGE_KEY = process.env.REACT_APP_TOKEN!;
const USER_DATA = process.env.REACT_APP_USER_DATA!;

interface AuthState {
  token: string | null;
}

interface LoginPayload {
  token: string;
}

const initialState: AuthState = {
  token: localStorage.getItem(TOKEN_STORAGE_KEY) || null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<LoginPayload>) => {
      state.token = action.payload.token;
      localStorage.setItem(TOKEN_STORAGE_KEY, action.payload.token);
      localStorage.setItem(USER_DATA, JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.token = null;
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      localStorage.removeItem(USER_DATA);
      localStorage.clear();
    },
  },
});

export const { loginSuccess, logout } = authSlice.actions;

interface LoginBody {
  email: string;
  password: string;
}

export const login = (body: LoginBody) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.post(LOGIN_API_URL, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.status) {
      dispatch(loginSuccess(response.data.data));

      return response.data;
    } else {
      return response.data;
    }
  } catch (err) {
    throw new Error(err as string);
  }
};

export const logoutUser = () => async (dispatch: AppDispatch) => {
  try {
    await dispatch(logout());
  } catch (err) {
    throw new Error(err as string);
  }
};

export default authSlice.reducer;
