import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchOrderList } from '../order/orderSlice';

// API endpoints
const PERMISSION_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-permissions`;
const ROLES_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-roles`;
const CLIENTS_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-clients`;
const DESIGNS_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-designs`;
const MODELS_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-models`;
const PARTS_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-parts`;
const VENDORS_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-vendors`;
const UPDATE_STATUS = `${process.env.REACT_APP_BASE_URL}/orders/status`;
const QR_CODE = `${process.env.REACT_APP_BASE_URL}/orders/items/by-id`;
const MIS_URL = `${process.env.REACT_APP_BASE_URL}/report/get-qr-report`;
const ORDER_URL = `${process.env.REACT_APP_BASE_URL}/helper/get-orders`;
const EMPLOYEE_URL = `${process.env.REACT_APP_BASE_URL}/helper/get-employees`;
const DESIGNATION_URL = `${process.env.REACT_APP_BASE_URL}/helper/get-designations`;
const DEPARTMENT_URL = `${process.env.REACT_APP_BASE_URL}/helper/get-departments`;

// Dependent
const DEPENDENT_MODELS_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-client-models`;
const DEPENDENT_PART_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-client-parts`;
const DEPENDENT_DESIGN_LIST = `${process.env.REACT_APP_BASE_URL}/helper/get-client-designs`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface ModelsListProps {
  permissionData: any[];
  roleData: any[];
  clientData: any[];
  designData: any[];
  modelData: any[];
  partData: any[];
  vendorData: any[];
  QRDataData: any[];
  qrCodeData: any[];
  orderData: any[];
  employeeData: any[];
  designationData: any[];
  departmentData: any[];

  // Dependent
  dependentModelData: any[];
  dependentPartData: any[];
  dependentDesignData: any[];

  misData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: ModelsListProps = {
  permissionData: [],
  roleData: [],
  clientData: [],
  designData: [],
  modelData: [],
  partData: [],
  vendorData: [],
  QRDataData: [],
  qrCodeData: [],
  misData: [],
  orderData: [],
  employeeData: [],
  designationData: [],
  departmentData: [],

  // Dependent
  dependentDesignData: [],
  dependentPartData: [],
  dependentModelData: [],

  status: null,
  message: null,
};

export const HelperSlice = createSlice({
  name: 'helperSlice',
  initialState,
  reducers: {
    // permission
    permissionListSuccess: (
      state,
      action: PayloadAction<{ permissionData: any[]; status: boolean; message: string }>,
    ) => {
      state.permissionData = action.payload.permissionData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    permissionListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    roleListSuccess: (
      state,
      action: PayloadAction<{ roleData: any[]; status: boolean; message: string }>,
    ) => {
      state.roleData = action.payload.roleData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    roleListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    clientListSuccess: (
      state,
      action: PayloadAction<{ clientData: any[]; status: boolean; message: string }>,
    ) => {
      state.clientData = action.payload.clientData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    clientListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    designListSuccess: (
      state,
      action: PayloadAction<{ designData: any[]; status: boolean; message: string }>,
    ) => {
      state.designData = action.payload.designData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    designListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    modelListSuccess: (
      state,
      action: PayloadAction<{ modelData: any[]; status: boolean; message: string }>,
    ) => {
      state.modelData = action.payload.modelData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    modelListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    orderListSuccess: (
      state,
      action: PayloadAction<{ orderData: any[]; status: boolean; message: string }>,
    ) => {
      state.orderData = action.payload.orderData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    orderListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    partListSuccess: (
      state,
      action: PayloadAction<{ partData: any[]; status: boolean; message: string }>,
    ) => {
      state.partData = action.payload.partData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    partListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    vendorListSuccess: (
      state,
      action: PayloadAction<{ vendorData: any[]; status: boolean; message: string }>,
    ) => {
      state.vendorData = action.payload.vendorData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    vendorListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // Status
    qrCodeStatusSuccess: (
      state,
      action: PayloadAction<{ qrCodeData: any[]; status: boolean; message: string }>,
    ) => {
      state.qrCodeData = action.payload.qrCodeData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    qrCodeStatusFalse: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    qrCodeListSuccess: (
      state,
      action: PayloadAction<{ QRDataData: any[]; status: boolean; message: string }>,
    ) => {
      state.QRDataData = action.payload.QRDataData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    qrCodeListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // Mis reports
    misReportSuccess: (
      state,
      action: PayloadAction<{ misData: any[]; status: boolean; message: string }>,
    ) => {
      state.misData = action.payload.misData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    misReportFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // Dependent
    dependentDesignListSuccess: (
      state,
      action: PayloadAction<{ dependentDesignData: any[]; status: boolean; message: string }>,
    ) => {
      state.dependentDesignData = action.payload.dependentDesignData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    dependentDesignListFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    dependentPartListSuccess: (
      state,
      action: PayloadAction<{ dependentPartData: any[]; status: boolean; message: string }>,
    ) => {
      state.dependentPartData = action.payload.dependentPartData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    dependentPartListFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    dependentModelListSuccess: (
      state,
      action: PayloadAction<{ dependentModelData: any[]; status: boolean; message: string }>,
    ) => {
      state.dependentModelData = action.payload.dependentModelData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    dependentModelListFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    employeeListSuccess: (
      state,
      action: PayloadAction<{ employeeData: any[]; status: boolean; message: string }>,
    ) => {
      state.employeeData = action.payload.employeeData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    employeeListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    designationListSuccess: (
      state,
      action: PayloadAction<{ designationData: any[]; status: boolean; message: string }>,
    ) => {
      state.designationData = action.payload.designationData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    designationListFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    departmentListSuccess: (
      state,
      action: PayloadAction<{ departmentData: any[]; status: boolean; message: string }>,
    ) => {
      state.departmentData = action.payload.departmentData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    departmentListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    orderIsPrintedSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    orderIsPrintedFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  permissionListSuccess,
  permissionListFailure,
  roleListSuccess,
  roleListFailure,
  clientListSuccess,
  clientListFailure,
  designListSuccess,
  designListFailure,
  modelListSuccess,
  modelListFailure,
  orderListSuccess,
  orderListFailure,
  partListSuccess,
  partListFailure,
  vendorListSuccess,
  vendorListFailure,
  qrCodeStatusSuccess,
  qrCodeStatusFalse,
  qrCodeListSuccess,
  qrCodeListFailure,
  misReportSuccess,
  misReportFailure,
  dependentDesignListSuccess,
  dependentDesignListFailure,
  dependentPartListSuccess,
  dependentPartListFailure,
  dependentModelListSuccess,
  dependentModelListFailure,
  employeeListSuccess,
  employeeListFailure,
  designationListSuccess,
  designationListFailure,
  departmentListSuccess,
  departmentListFailure,
  orderIsPrintedSuccess,
  orderIsPrintedFailure,
} = HelperSlice.actions;

export const fetchPermissionsListFromHelper = () => async (dispatch: AppDispatch) => {
  const token = getToken();

  try {
    const response = await axios.get(PERMISSION_LIST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      permissionListSuccess({
        status: response.data.status,
        message: response.data.message,
        permissionData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      permissionListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchRolesListFromHelper = () => async (dispatch: AppDispatch) => {
  const token = getToken();

  try {
    const response = await axios.get(`${ROLES_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      roleListSuccess({
        status: response.data.status,
        message: response.data.message,
        roleData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      roleListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchClientsListFromHelper = (search: any) => async (dispatch: AppDispatch) => {
  const token = getToken();
  let api_url = CLIENTS_LIST;
  if (search) {
    api_url = `${CLIENTS_LIST}?search=${search}`;
  }
  try {
    const response = await axios.get(api_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      clientListSuccess({
        status: response.data.status,
        message: response.data.message,
        clientData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      clientListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchDesignsListFromHelper = () => async (dispatch: AppDispatch) => {
  const token = getToken();
  try {
    const response = await axios.get(`${DESIGNS_LIST}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      designListSuccess({
        status: response.data.status,
        message: response.data.message,
        designData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      designListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchModelsListFromHelper = (search: any) => async (dispatch: AppDispatch) => {
  const token = getToken();
  let api_url = `${MODELS_LIST}`;
  if (search) {
    api_url = `${MODELS_LIST}?search=${search}`;
  }
  try {
    const response = await axios.get(api_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      modelListSuccess({
        status: response.data.status,
        message: response.data.message,
        modelData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      modelListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchPartsListFromHelper = (search: any) => async (dispatch: AppDispatch) => {
  const token = getToken();
  let api_url = PARTS_LIST;
  if (search) {
    api_url = `${PARTS_LIST}?search=${search}`;
  }

  try {
    const response = await axios.get(api_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      partListSuccess({
        status: response.data.status,
        message: response.data.message,
        partData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      partListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchOrderListFromHelper = (search: any) => async (dispatch: AppDispatch) => {
  const token = getToken();
  let api_url = `${ORDER_URL}`;
  if (search) {
    api_url = `${ORDER_URL}?search=${search}`;
  }
  try {
    const response = await axios.get(api_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      orderListSuccess({
        status: response.data.status,
        message: response.data.message,
        orderData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      orderListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchVendorsListFromHelper = (search: any) => async (dispatch: AppDispatch) => {
  const token = getToken();
  let api_url = VENDORS_LIST;
  if (search) {
    api_url = `${VENDORS_LIST}?search=${search}`;
  }

  try {
    const response = await axios.get(api_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      vendorListSuccess({
        status: response.data.status,
        message: response.data.message,
        vendorData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      vendorListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const updateStatusOfOrder = (id: number, body: any) => async (dispatch: AppDispatch) => {
  const token = getToken();
  try {
    const response = await axios.patch(`${UPDATE_STATUS}/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      qrCodeStatusSuccess({
        status: response.data.status,
        message: response.data.message,
        qrCodeData: response.data.data,
      }),
    );

    dispatch(fetchOrderList(0, 10, ''));

    return response;
  } catch (error: any) {
    dispatch(
      qrCodeStatusFalse({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const getQRCodeDetails = (id: number) => async (dispatch: AppDispatch) => {
  const token = getToken();
  try {
    const response = await axios.get(`${QR_CODE}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      qrCodeListSuccess({
        status: response.data.status,
        message: response.data.message,
        QRDataData: response.data.data,
      }),
    );

    dispatch(fetchOrderList(0, 10, ''));

    return response;
  } catch (error: any) {
    dispatch(
      qrCodeListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const downloadMisReport =
  (client_id: string, model_id: string, status: any = '', start_date: string, end_date: string) =>
  async (dispatch: AppDispatch) => {
    const token = getToken();
    const api_url = `${MIS_URL}?client_id=${client_id}&model_id=${model_id}&status=${status}&start_date=${start_date}&end_date=${end_date}`;
    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        misReportSuccess({
          misData: response.data.data,
          status: true,
          message: response.data.message,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        misReportFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

// Dependent model
export const fetchDependentModelsListFromHelper =
  (client_id: number, search: any) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${DEPENDENT_MODELS_LIST}?client_id=${client_id}`;
    if (search) {
      api_url = `${DEPENDENT_MODELS_LIST}?client_id=${client_id}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        dependentModelListSuccess({
          status: response.data.status,
          message: response.data.message,
          dependentModelData: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        dependentModelListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const fetchDependentPartListFromHelper =
  (client_id: number, selectedModel: number, search: any) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${DEPENDENT_PART_LIST}?client_id=${client_id}&model_id=${selectedModel}`;
    if (search) {
      api_url = `${DEPENDENT_PART_LIST}?client_id=${client_id}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        dependentPartListSuccess({
          status: response.data.status,
          message: response.data.message,
          dependentPartData: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        dependentPartListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const fetchDependentDesignListFromHelper =
  (client_id: number, selectedPart: number, search: any) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${DEPENDENT_DESIGN_LIST}?client_id=${client_id}&part_id=${selectedPart}`;
    if (search) {
      api_url = `${DEPENDENT_DESIGN_LIST}?client_id=${client_id}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        dependentDesignListSuccess({
          status: response.data.status,
          message: response.data.message,
          dependentDesignData: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        dependentDesignListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const fetchEmployeesListFromHelper = (search: any) => async (dispatch: AppDispatch) => {
  const token = getToken();
  let api_url = `${EMPLOYEE_URL}`;
  if (search) {
    api_url = `${EMPLOYEE_URL}?search=${search}`;
  }

  try {
    const response = await axios.get(api_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      employeeListSuccess({
        status: response.data.status,
        message: response.data.message,
        employeeData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      employeeListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchDesignationFromHelper = (search: any) => async (dispatch: AppDispatch) => {
  const token = getToken();
  let api_url = `${DESIGNATION_URL}`;
  if (search) {
    api_url = `${DESIGNATION_URL}?search=${search}`;
  }

  try {
    const response = await axios.get(api_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      designationListSuccess({
        status: response.data.status,
        message: response.data.message,
        designationData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      designationListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchDepartmentFromHelper = (search: any) => async (dispatch: AppDispatch) => {
  const token = getToken();
  let api_url = `${DEPARTMENT_URL}`;
  if (search) {
    api_url = `${DEPARTMENT_URL}?search=${search}`;
  }

  try {
    const response = await axios.get(api_url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      departmentListSuccess({
        status: response.data.status,
        message: response.data.message,
        departmentData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      departmentListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const updateOrderIsPrint = (id: any, body: any) => async (dispatch: AppDispatch) => {
  const token = getToken();

  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_BASE_URL}/helper/mark-is-printed/${id}`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    dispatch(
      orderIsPrintedSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      orderIsPrintedFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export default HelperSlice.reducer;
