import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { logout } from '../auth/LoginSlice';

// API of userList
const USER_LIST = `${process.env.REACT_APP_BASE_URL}/users/list`;
const USER_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/users/by-id`;
const USER_ADD = `${process.env.REACT_APP_BASE_URL}/users/add`;
const USER_UPDATE = `${process.env.REACT_APP_BASE_URL}/users/update`;
const USER_DELETE = `${process.env.REACT_APP_BASE_URL}/users/delete`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface UserListState {
  data: any[];
  userData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: UserListState = {
  data: [],
  userData: [],
  status: null,
  message: null,
};

export const UserListSlice = createSlice({
  name: 'UserList',
  initialState,
  reducers: {
    // get user list
    userListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    userDataSuccess: (
      state,
      action: PayloadAction<{ userData: any[]; status: boolean; message: string }>,
    ) => {
      state.userData = action.payload.userData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDataFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user add
    userAddSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userAddFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user update
    userUpdateSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userUpdateFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user delete
    userDeleteSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDeleteFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  userListSuccess,
  userListFailure,
  userDataSuccess,
  userDataFailure,
  userAddSuccess,
  userAddFailure,
  userUpdateSuccess,
  userUpdateFailure,
  userDeleteSuccess,
  userDeleteFailure,
} = UserListSlice.actions;

export const fetchUserList =
  (page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${USER_LIST}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        userListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      if (error?.code === 401 && error?.message === 'The token is invalid ') {
        await dispatch(logout());
      }

      dispatch(
        userListFailure({
          status: false,
          message: error.message,
        }),
      );
    }
  };

export const getUserDetails = (id: number) => async (dispatch: AppDispatch) => {
  const token = getToken();
  try {
    const response = await axios.get(`${USER_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      userDataSuccess({
        status: response.data.status,
        message: response.data.message,
        userData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    if (error?.code === 401 && error?.message === 'The token is invalid ') {
      await dispatch(logout());
    }

    dispatch(
      userDataFailure({
        status: false,
        message: error.message,
      }),
    );
  }
};

export const addUser = (body: any, search: string) => async (dispatch: AppDispatch) => {
  const token = getToken();
  try {
    const response = await axios.post(USER_ADD, JSON.stringify(body), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      userAddSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchUserList(1, 10, search));

    return response;
  } catch (error: any) {
    if (error?.code === 401 && error?.message === 'The token is invalid ') {
      await dispatch(logout());
    }

    dispatch(
      userAddFailure({
        status: false,
        message: error.message,
      }),
    );
  }
};

export const updateUser =
  (id: number, body: any, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    try {
      const response = await axios.put(`${USER_UPDATE}/${id}`, JSON.stringify(body), {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      dispatch(
        userUpdateSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchUserList(1, 10, search));

      return response;
    } catch (error: any) {
      if (error?.code === 401 && error?.message === 'The token is invalid ') {
        await dispatch(logout());
      }

      dispatch(
        userUpdateFailure({
          status: false,
          message: error.message,
        }),
      );
    }
  };

export const deleteUser = (id: number | null, search: string) => async (dispatch: AppDispatch) => {
  const token = getToken();
  try {
    const response = await axios.delete(`${USER_DELETE}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      userDeleteSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchUserList(1, 10, search));

    return response;
  } catch (error: any) {
    if (error?.code === 401 && error?.message === 'The token is invalid ') {
      await dispatch(logout());
    }

    dispatch(
      userDeleteFailure({
        status: false,
        message: error.message,
      }),
    );
  }
};

export default UserListSlice.reducer;
