import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const QRCODE_LIST = `${process.env.REACT_APP_BASE_URL}/qr-code/list`;
const QRCODE_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/qr-code/by-id`;
const QRCODE_UPDATE = `${process.env.REACT_APP_BASE_URL}/qr-code/update`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

// On app initialization
const token = getToken();

interface VendorListProps {
  data: any[];
  qrCodeData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: VendorListProps = {
  data: [],
  qrCodeData: [],
  status: null,
  message: null,
};

export const QRCodeSlice = createSlice({
  name: 'QRCodeSlice',
  initialState,
  reducers: {
    // get user list
    qrCodeListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    qrCodeListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    QRcodeDetailsSuccess: (
      state,
      action: PayloadAction<{ qrCodeData: any[]; status: boolean; message: string }>,
    ) => {
      state.qrCodeData = action.payload.qrCodeData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    QRcodeDetailsFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user update
    QRcodeUpdateSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    QRcodeUpdateFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  qrCodeListSuccess,
  qrCodeListFailure,
  QRcodeDetailsSuccess,
  QRcodeDetailsFailure,
  QRcodeUpdateSuccess,
  QRcodeUpdateFailure,
} = QRCodeSlice.actions;

export const fetchQrCodeList =
  (page: number, rowsPerPage: number, search: string, isDamaged: number, selectedOption: any) =>
  async (dispatch: AppDispatch) => {
    let api_url = `${QRCODE_LIST}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    if (selectedOption) {
      api_url = `${api_url}&order_id=${selectedOption}`;
    }

    if (isDamaged) {
      api_url = `${api_url}&status=${isDamaged}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        qrCodeListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        qrCodeListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getQRcodeDetailById = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const response = await axios.get(`${QRCODE_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      QRcodeDetailsSuccess({
        status: response.data.status,
        message: response.data.message,
        qrCodeData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      QRcodeDetailsFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const updateQRcode =
  (id: number, body: any, search: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.put(`${QRCODE_UPDATE}/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(
        QRcodeUpdateSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchQrCodeList(1, 10, search, 1, ''));

      return response;
    } catch (error: any) {
      dispatch(
        QRcodeUpdateFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export default QRCodeSlice.reducer;
