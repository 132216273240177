import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const PERMISSIONS_LIST = `${process.env.REACT_APP_BASE_URL}/permissions/list`;
const PERMISSIONS_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/permissions/by-id`;
const PERMISSIONS_ADD = `${process.env.REACT_APP_BASE_URL}/permissions/add`;
const PERMISSIONS_UPDATE = `${process.env.REACT_APP_BASE_URL}/permissions/update`;
const PERMISSIONS_DELETE = `${process.env.REACT_APP_BASE_URL}/permissions/delete`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface PermissionsListProps {
  data: any[];
  userData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: PermissionsListProps = {
  data: [],
  userData: [],
  status: null,
  message: null,
};

export const PermissionsSlice = createSlice({
  name: 'PermissionsSlice',
  initialState,
  reducers: {
    // get user list
    userListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    userDataSuccess: (
      state,
      action: PayloadAction<{ userData: any[]; status: boolean; message: string }>,
    ) => {
      state.userData = action.payload.userData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDataFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user add
    userAddSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userAddFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user update
    userUpdateSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userUpdateFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user delete
    userDeleteSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDeleteFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  userListSuccess,
  userListFailure,
  userDataSuccess,
  userDataFailure,
  userAddSuccess,
  userAddFailure,
  userUpdateSuccess,
  userUpdateFailure,
  userDeleteSuccess,
  userDeleteFailure,
} = PermissionsSlice.actions;

export const fetchPermissionsList =
  (page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${PERMISSIONS_LIST}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(
        userListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        userListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getPermssionsDetailById = (id: number) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(`${PERMISSIONS_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      userDataSuccess({
        status: response.data.status,
        message: response.data.message,
        userData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      userDataFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const addPermissions = (body: any, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.post(PERMISSIONS_ADD, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    dispatch(
      userAddSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchPermissionsList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      userAddFailure({
        status: false,
        message: error.message,
      }),
    );
    console.error(error);
  }
};

export const updatePermissions =
  (id: number, body: any, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }
    try {
      const response = await axios.put(`${PERMISSIONS_UPDATE}/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(
        userUpdateSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchPermissionsList(1, 10, search));

      return response;
    } catch (error: any) {
      dispatch(
        userUpdateFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const deletePermissions =
  (id: number | null, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }
    try {
      const response = await axios.delete(`${PERMISSIONS_DELETE}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        userDeleteSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchPermissionsList(1, 10, search));

      return response;
    } catch (error: any) {
      dispatch(
        userDeleteFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export default PermissionsSlice.reducer;
