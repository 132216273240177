import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const DEPARTMENT_LIST = `${process.env.REACT_APP_BASE_URL}/department/list`;
const DEPARTMENT_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/department/by-id`;
const DEPARTMENT_ADD = `${process.env.REACT_APP_BASE_URL}/department/add`;
const DEPARTMENT_UPDATE = `${process.env.REACT_APP_BASE_URL}/department/update`;
const DEPARTMENT_DELETE = `${process.env.REACT_APP_BASE_URL}/department/delete`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface DepartmentListProps {
  data: any[];
  departmentData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: DepartmentListProps = {
  data: [],
  departmentData: [],
  status: null,
  message: null,
};

export const DepartmentSlice = createSlice({
  name: 'DepartmentSlice',
  initialState,
  reducers: {
    // get user list
    departmentListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    departmentListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    departmentDetailsSuccess: (
      state,
      action: PayloadAction<{ departmentData: any[]; status: boolean; message: string }>,
    ) => {
      state.departmentData = action.payload.departmentData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    departmentDetailsFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user add
    departmentAddSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    departmentAddFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user update
    departmentUpdateSuccess: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    departmentUpdateFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user delete
    departmentDeleteSuccess: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    departmentDeleteFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  departmentListSuccess,
  departmentListFailure,
  departmentDetailsSuccess,
  departmentDetailsFailure,
  departmentAddSuccess,
  departmentAddFailure,
  departmentUpdateSuccess,
  departmentUpdateFailure,
  departmentDeleteSuccess,
  departmentDeleteFailure,
} = DepartmentSlice.actions;

export const fetchDepartmentList =
  (page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${DEPARTMENT_LIST}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        departmentListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        departmentListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getDepartmentDetailById = (id: number) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(`${DEPARTMENT_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      departmentDetailsSuccess({
        status: response.data.status,
        message: response.data.message,
        departmentData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      departmentDetailsFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const addDepartment = (body: any, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.post(DEPARTMENT_ADD, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    dispatch(
      departmentAddSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchDepartmentList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      departmentAddFailure({
        status: false,
        message: error.message,
      }),
    );
    console.error(error);
  }
};

export const updateDepartment =
  (id: number, body: any, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }
    try {
      const response = await axios.put(`${DEPARTMENT_UPDATE}/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(
        departmentUpdateSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchDepartmentList(1, 10, search));

      return response;
    } catch (error: any) {
      dispatch(
        departmentUpdateFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const deleteDepartment =
  (id: any, page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }
    try {
      const response = await axios.delete(`${DEPARTMENT_DELETE}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        departmentDeleteSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchDepartmentList(page, rowsPerPage, search));

      return response;
    } catch (error: any) {
      dispatch(
        departmentDeleteFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export default DepartmentSlice.reducer;
