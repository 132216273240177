import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const ROLES_LIST = `${process.env.REACT_APP_BASE_URL}/roles/list`;
const ROLES_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/roles/by-id`;
const ROLES_ADD = `${process.env.REACT_APP_BASE_URL}/roles/add`;
const ROLES_UPDATE = `${process.env.REACT_APP_BASE_URL}/roles/update`;
const ROLES_DELETE = `${process.env.REACT_APP_BASE_URL}/roles/delete`;
const ROLES_PERMISSION_LIST = `${process.env.REACT_APP_BASE_URL}/roles/get-permissions`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface RolesListProps {
  data: any[];
  rolesData: any[];
  permissionData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: RolesListProps = {
  data: [],
  rolesData: [],
  permissionData: [],
  status: null,
  message: null,
};

export const RoleSlice = createSlice({
  name: 'UserList',
  initialState,
  reducers: {
    // get user list
    userListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    userDataSuccess: (
      state,
      action: PayloadAction<{ rolesData: any[]; status: boolean; message: string }>,
    ) => {
      state.rolesData = action.payload.rolesData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDataFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user add
    userAddSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userAddFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user update
    userUpdateSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userUpdateFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user delete
    userDeleteSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDeleteFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // permission
    permissionListSuccess: (
      state,
      action: PayloadAction<{ permissionData: any[]; status: boolean; message: string }>,
    ) => {
      state.permissionData = action.payload.permissionData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    permissionListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  userListSuccess,
  userListFailure,
  userDataSuccess,
  userDataFailure,
  userAddSuccess,
  userAddFailure,
  userUpdateSuccess,
  userUpdateFailure,
  userDeleteSuccess,
  userDeleteFailure,
  permissionListSuccess,
  permissionListFailure,
} = RoleSlice.actions;

export const fetchRolesList =
  (page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${ROLES_LIST}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }
    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        userListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        userListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getRolesDetails = (id: number) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(`${ROLES_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      userDataSuccess({
        status: response.data.status,
        message: response.data.message,
        rolesData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      userDataFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const addRoles = (body: any, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.post(ROLES_ADD, JSON.stringify(body), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      userAddSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchRolesList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      userAddFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const updateRoles = (id: number, body: any, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.put(`${ROLES_UPDATE}/${id}`, JSON.stringify(body), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      userUpdateSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchRolesList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      userUpdateFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const deleteRoles = (id: number | null, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.delete(`${ROLES_DELETE}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      userDeleteSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchRolesList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      userDeleteFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchPermissionsListFromRoles = () => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(ROLES_PERMISSION_LIST, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      permissionListSuccess({
        status: response.data.status,
        message: response.data.message,
        permissionData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      permissionListFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export default RoleSlice.reducer;
