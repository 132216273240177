import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const CLIENT_LIST = `${process.env.REACT_APP_BASE_URL}/clients/list`;
const CLIENT_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/clients/by-id`;
const CLIENT_ADD = `${process.env.REACT_APP_BASE_URL}/clients/add`;
const CLIENT_UPDATE = `${process.env.REACT_APP_BASE_URL}/clients/update`;
const CLIENT_DELETE = `${process.env.REACT_APP_BASE_URL}/clients/delete`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface DesignListProps {
  data: any[];
  userData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: DesignListProps = {
  data: [],
  userData: [],
  status: null,
  message: null,
};

export const ClientSlice = createSlice({
  name: 'ClientSlice',
  initialState,
  reducers: {
    // get user list
    userListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    userDataSuccess: (
      state,
      action: PayloadAction<{ userData: any[]; status: boolean; message: string }>,
    ) => {
      state.userData = action.payload.userData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDataFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user add
    userAddSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userAddFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user update
    userUpdateSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userUpdateFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user delete
    userDeleteSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDeleteFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  userListSuccess,
  userListFailure,
  userDataSuccess,
  userDataFailure,
  userAddSuccess,
  userAddFailure,
  userUpdateSuccess,
  userUpdateFailure,
  userDeleteSuccess,
  userDeleteFailure,
} = ClientSlice.actions;

export const fetchClientList =
  (page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${CLIENT_LIST}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        userListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        userListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getClientDetailById = (id: number) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(`${CLIENT_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      userDataSuccess({
        status: response.data.status,
        message: response.data.message,
        userData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      userDataFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const addClient = (body: any, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const formData = new FormData();
    for (const key in body) {
      if (key === 'logo' && body[key] instanceof File) {
        formData.append(key, body[key]);
      } else {
        formData.append(key, body[key]);
      }
    }
    const response = await axios.post(CLIENT_ADD, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem(localToken)}`,
      },
    });
    console.log(token, response);

    dispatch(
      userAddSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchClientList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      userAddFailure({
        status: false,
        message: error.message,
      }),
    );
    console.error(error);
  }
};

export const updateClient =
  (id: number, body: any, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }
    try {
      const formData = new FormData();
      for (const key in body) {
        if (key === 'logo' && body[key] instanceof File) {
          formData.append(key, body[key]);
        } else {
          formData.append(key, body[key]);
        }
      }
      const response = await axios.put(`${CLIENT_UPDATE}/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        userUpdateSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchClientList(1, 10, search));

      return response;
    } catch (error: any) {
      dispatch(
        userUpdateFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const deleteClient = (id: any, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.delete(`${CLIENT_DELETE}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      userDeleteSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchClientList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      userDeleteFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export default ClientSlice.reducer;
