import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const PRODUCTS_LIST = `${process.env.REACT_APP_BASE_URL}/products/list`;
const PRODUCTS_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/products/by-id`;
const PRODUCTS_ADD = `${process.env.REACT_APP_BASE_URL}/products/add`;
const PRODUCTS_UPDATE = `${process.env.REACT_APP_BASE_URL}/products/update`;
const PRODUCTS_DELETE = `${process.env.REACT_APP_BASE_URL}/products/delete`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface ProductsListProps {
  data: any[];
  productData: any[];
  downloadURL: string;
  status: boolean | null;
  message: string | null;
}

const initialState: ProductsListProps = {
  data: [],
  productData: [],
  downloadURL: '',
  status: null,
  message: null,
};

export const ProductsSlice = createSlice({
  name: 'ProductsSlice',
  initialState,
  reducers: {
    // get user list
    productListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    productListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    productDetailsSuccess: (
      state,
      action: PayloadAction<{ productData: any[]; status: boolean; message: string }>,
    ) => {
      state.productData = action.payload.productData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    productDetailsFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user add
    productAddSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    productAddFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user update
    productUpdateSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    productUpdateFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user delete
    productDeleteSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    productDeleteFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    downloadExcelSuccess: (
      state,
      action: PayloadAction<{ downloadURL: string; status: boolean; message: string }>,
    ) => {
      state.downloadURL = action.payload.downloadURL;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    downloadExcelFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  productListSuccess,
  productListFailure,
  productDetailsSuccess,
  productDetailsFailure,
  productAddSuccess,
  productAddFailure,
  productUpdateSuccess,
  productUpdateFailure,
  productDeleteSuccess,
  productDeleteFailure,
  downloadExcelSuccess,
  downloadExcelFailure,
} = ProductsSlice.actions;

export const fetchProductsList =
  (page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${PRODUCTS_LIST}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(
        productListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        productListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getProductsDetailById = (id: number) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(`${PRODUCTS_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    dispatch(
      productDetailsSuccess({
        status: response.data.status,
        message: response.data.message,
        productData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      productDetailsFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const addProducts = (body: any, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }

  const formData = new FormData();
  for (const key in body) {
    if (key === 'product_image' && body[key] instanceof File) {
      formData.append(key, body[key]);
    } else {
      formData.append(key, body[key]);
    }
  }

  try {
    const response = await axios.post(PRODUCTS_ADD, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    dispatch(
      productAddSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchProductsList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      productAddFailure({
        status: false,
        message: error.message,
      }),
    );
    console.error(error);
  }
};

export const updateProducts =
  (id: number, body: any, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }

    const formData = new FormData();
    for (const key in body) {
      if (key === 'product_image' && body[key] instanceof File) {
        formData.append(key, body[key]);
      } else {
        formData.append(key, body[key]);
      }
    }

    try {
      const response = await axios.put(`${PRODUCTS_UPDATE}/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(
        productUpdateSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchProductsList(1, 10, search));

      return response;
    } catch (error: any) {
      dispatch(
        productUpdateFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const deleteProducts =
  (id: number | null, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }
    try {
      const response = await axios.delete(`${PRODUCTS_DELETE}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        productDeleteSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchProductsList(1, 10, search));

      return response;
    } catch (error: any) {
      dispatch(
        productDeleteFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const downloadExcelFile = () => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/report/get-products-report`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      },
    );
    dispatch(
      downloadExcelSuccess({
        downloadURL: response.data.data,
        status: response.data.status,
        message: response.data.message,
      }),
    );

    return response?.data;
  } catch (error: any) {
    dispatch(
      productDetailsFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};
export default ProductsSlice.reducer;
