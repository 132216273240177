import { AppDispatch } from 'src/store/Store';
import axios from '../../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API of userList
const ORDER_LIST = `${process.env.REACT_APP_BASE_URL}/orders/list`;
const ORDER_GET_BY_ID = `${process.env.REACT_APP_BASE_URL}/orders/by-id`;
const ORDER_ADD = `${process.env.REACT_APP_BASE_URL}/orders/add`;
const ORDER_UPDATE = `${process.env.REACT_APP_BASE_URL}/orders/update`;
const ORDER_DELETE = `${process.env.REACT_APP_BASE_URL}/orders/delete`;
const DAMAEGED_QR_URL = `${process.env.REACT_APP_BASE_URL}/orders/get-damaged-qrs`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);

interface VendorListProps {
  data: any[];
  userData: any[];
  damagedQrData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: VendorListProps = {
  data: [],
  userData: [],
  damagedQrData: [],
  status: null,
  message: null,
};

export const OrderSlice = createSlice({
  name: 'OrderSlice',
  initialState,
  reducers: {
    // get user list
    userListSuccess: (
      state,
      action: PayloadAction<{ data: any[]; status: boolean; message: string }>,
    ) => {
      state.data = action.payload.data;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // get User details
    userDataSuccess: (
      state,
      action: PayloadAction<{ userData: any[]; status: boolean; message: string }>,
    ) => {
      state.userData = action.payload.userData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDataFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user add
    userAddSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userAddFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user update
    userUpdateSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userUpdateFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // user delete
    userDeleteSuccess: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    userDeleteFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // damagedQrData
    damagedQrDataSuccess: (
      state,
      action: PayloadAction<{ damagedQrData: any; status: boolean; message: string }>,
    ) => {
      state.damagedQrData = action.payload.damagedQrData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    damagedQrDataFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  userListSuccess,
  userListFailure,
  userDataSuccess,
  userDataFailure,
  userAddSuccess,
  userAddFailure,
  userUpdateSuccess,
  userUpdateFailure,
  userDeleteSuccess,
  userDeleteFailure,
  damagedQrDataSuccess,
  damagedQrDataFailure,
} = OrderSlice.actions;

export const fetchOrderList =
  (page: number, rowsPerPage: number, search: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    let api_url = `${ORDER_LIST}?page=${page}&limit=${rowsPerPage}`;

    if (search) {
      api_url = `${api_url}&search=${search}`;
    }

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        userListSuccess({
          status: response.data.status,
          message: response.data.message,
          data: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        userListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getOrderDetailById = (id: number) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.get(`${ORDER_GET_BY_ID}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      userDataSuccess({
        status: response.data.status,
        message: response.data.message,
        userData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      userDataFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const addOrder = (body: any, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.post(ORDER_ADD, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    dispatch(
      userAddSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchOrderList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      userAddFailure({
        status: false,
        message: error.message,
      }),
    );
    console.error(error);
  }
};

export const updateOrder =
  (id: number, body: any, search: string) => async (dispatch: AppDispatch) => {
    let token = localStorage.getItem(localToken);
    if (token) {
      token = token;
    }
    try {
      const response = await axios.put(`${ORDER_UPDATE}/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      dispatch(
        userUpdateSuccess({
          status: response.data.status,
          message: response.data.message,
        }),
      );
      dispatch(fetchOrderList(1, 10, search));

      return response;
    } catch (error: any) {
      dispatch(
        userUpdateFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const deleteOrder = (id: any | null, search: string) => async (dispatch: AppDispatch) => {
  let token = localStorage.getItem(localToken);
  if (token) {
    token = token;
  }
  try {
    const response = await axios.delete(`${ORDER_DELETE}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      userDeleteSuccess({
        status: response.data.status,
        message: response.data.message,
      }),
    );
    dispatch(fetchOrderList(1, 10, search));

    return response;
  } catch (error: any) {
    dispatch(
      userDeleteFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export const fetchDamagedQRList = (id: number) => async (dispatch: AppDispatch) => {
  const token = getToken();

  try {
    const response = await axios.get(`${DAMAEGED_QR_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(
      damagedQrDataSuccess({
        status: response.data.status,
        message: response.data.message,
        damagedQrData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      damagedQrDataFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};
export default OrderSlice.reducer;
