import { AppDispatch } from 'src/store/Store';
import axios from '../../utils/axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// API endpoints
const TOTAL_LIST = `${process.env.REACT_APP_BASE_URL}/report/dashboard/get-totals`;
const CLIENT_WISE_URL = `${process.env.REACT_APP_BASE_URL}/report/dashboard/get-client-wise-production`;
const CLIENT_MODEL_WISE_URL = `${process.env.REACT_APP_BASE_URL}/report/dashboard/get-client-modelwise-production`;
const TREND_ANALYSIS_URL = `${process.env.REACT_APP_BASE_URL}/report/dashboard/get-trend-analysis`;
const TOP_TEN_URL = `${process.env.REACT_APP_BASE_URL}/report/dashboard/get-topten-sales`;

const localToken = process.env.REACT_APP_TOKEN!;
const getToken = () => localStorage.getItem(localToken);
const token = getToken();

interface DashBoardListProps {
  totalData: any[];
  clientWiseData: any[];
  clientModelWiseData: any[];
  topTenClientData: any[];
  trendAnalysisData: any[];
  status: boolean | null;
  message: string | null;
}

const initialState: DashBoardListProps = {
  totalData: [],
  clientWiseData: [],
  clientModelWiseData: [],
  topTenClientData: [],
  trendAnalysisData: [],
  status: null,
  message: null,
};

export const DashBoardSlice = createSlice({
  name: 'DashBoardSlice',
  initialState,
  reducers: {
    // permission
    DashBoardListSuccess: (
      state,
      action: PayloadAction<{ totalData: any[]; status: boolean; message: string }>,
    ) => {
      state.totalData = action.payload.totalData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    DashBoardListFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // clientWise data
    clientWiseSuccess: (
      state,
      action: PayloadAction<{ clientWiseData: any[]; status: boolean; message: string }>,
    ) => {
      state.clientWiseData = action.payload.clientWiseData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    clientWiseFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // client model wise
    clientModelWiseSuccess: (
      state,
      action: PayloadAction<{ clientModelWiseData: any[]; status: boolean; message: string }>,
    ) => {
      state.clientModelWiseData = action.payload.clientModelWiseData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    clientModelWiseFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // TrendAnalysis
    trendAnalysisSuccess: (
      state,
      action: PayloadAction<{ trendAnalysisData: any[]; status: boolean; message: string }>,
    ) => {
      state.trendAnalysisData = action.payload.trendAnalysisData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    trendAnalysisFailure: (state, action: PayloadAction<{ status: boolean; message: string }>) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },

    // Top 10 Table
    topTenClientDataSuccess: (
      state,
      action: PayloadAction<{ topTenClientData: any[]; status: boolean; message: string }>,
    ) => {
      state.topTenClientData = action.payload.topTenClientData;
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
    topTenClientDataFailure: (
      state,
      action: PayloadAction<{ status: boolean; message: string }>,
    ) => {
      state.status = action.payload.status;
      state.message = action.payload.message;
    },
  },
});

export const {
  DashBoardListSuccess,
  DashBoardListFailure,
  clientWiseSuccess,
  clientWiseFailure,
  clientModelWiseSuccess,
  clientModelWiseFailure,
  topTenClientDataSuccess,
  topTenClientDataFailure,
  trendAnalysisSuccess,
  trendAnalysisFailure,
} = DashBoardSlice.actions;

export const getTotalOfDashBoard =
  (start_date?: string, end_date?: string) => async (dispatch: AppDispatch) => {
    const token = getToken();
    const api_url = TOTAL_LIST;

    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...(start_date && { start_date }),
          ...(end_date && { end_date }),
        },
      });

      dispatch(
        DashBoardListSuccess({
          status: response.data.status,
          message: response.data.message,
          totalData: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        DashBoardListFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getClientWiseProduction =
  (start_date?: string, end_date?: string) => async (dispatch: AppDispatch) => {
    try {
      const response = await axios.get(CLIENT_WISE_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...(start_date && { start_date }),
          ...(end_date && { end_date }),
        },
      });

      dispatch(
        clientWiseSuccess({
          status: response.data.status,
          message: response.data.message,
          clientWiseData: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        clientWiseFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getClientModelWiseProduction =
  (start_date?: string, end_date?: string, client_id?: any) => async (dispatch: AppDispatch) => {
    let api_url = CLIENT_MODEL_WISE_URL;

    if (client_id) {
      api_url = `${CLIENT_MODEL_WISE_URL}?client_id=${client_id}`;
    }
    try {
      const response = await axios.get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...(start_date && { start_date }),
          ...(end_date && { end_date }),
        },
      });

      dispatch(
        clientModelWiseSuccess({
          status: response.data.status,
          message: response.data.message,
          clientModelWiseData: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        clientModelWiseFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getTopTenClientData =
  (start_date?: string, end_date?: string) => async (dispatch: AppDispatch) => {
    const token = getToken();

    try {
      const response = await axios.get(TOP_TEN_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ...(start_date && { start_date }),
          ...(end_date && { end_date }),
        },
      });

      dispatch(
        topTenClientDataSuccess({
          status: response.data.status,
          message: response.data.message,
          topTenClientData: response.data.data,
        }),
      );

      return response;
    } catch (error: any) {
      dispatch(
        topTenClientDataFailure({
          status: false,
          message: error.message,
        }),
      );
      console.log(error);
    }
  };

export const getTrendAnalysisOfDashBoard = () => async (dispatch: AppDispatch) => {
  const token = getToken();

  try {
    const response = await axios.get(TREND_ANALYSIS_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch(
      trendAnalysisSuccess({
        status: response.data.status,
        message: response.data.message,
        trendAnalysisData: response.data.data,
      }),
    );

    return response;
  } catch (error: any) {
    dispatch(
      trendAnalysisFailure({
        status: false,
        message: error.message,
      }),
    );
    console.log(error);
  }
};

export default DashBoardSlice.reducer;
